(function () {
    angular.module('informaApp')
        .component('chartViewProfileFilter', {
            templateUrl: 'components/chart-view-profile/chart-view-profile-filter/template.html',
            controller: ChartViewProfileFilterComponent,
            bindings: {
                filter: '<',
                onFilterChanges: '<'
            }
        });

    function ChartViewProfileFilterComponent(MetricsTypes, MetricsHelper, DurationTypeEnum) {
        this.metrics = [
            {id: MetricsTypes.pos, title: MetricsHelper.pos},
            {id: MetricsTypes.loa, title: MetricsHelper.loa},
            {id: MetricsTypes.duration, title: MetricsHelper.duration}
        ];

        this.durationTypes = DurationTypeEnum.types.map((durationType) => ({title: durationType}));

        this.metricDurationId = MetricsTypes.duration;

        this.currentMetric = this.metrics[0];
        this.currentDurationType = this.durationTypes[0];

        this.$onChanges = (changes) => {
            if (changes.filter && this.filter) {
                const {metric, durationType} = mapFilter.call(this, MetricsTypes, DurationTypeEnum);

                this.currentMetric = this.metrics.find(x => x.id === metric) || this.metrics[0];
                this.currentDurationType = this.durationTypes.find(x => x.title === durationType) || this.durationTypes[0];

                emitOnFilterChanges.call(this, MetricsTypes, DurationTypeEnum);
            }

            if (changes.onFilterChanges) {
                emitOnFilterChanges.call(this, MetricsTypes, DurationTypeEnum);
            }
        };

        this.emitOnFilterChanges = () => {
            emitOnFilterChanges.call(this, MetricsTypes, DurationTypeEnum);
        };
    }

    function emitOnFilterChanges(MetricsTypes, DurationTypeEnum) {
        if (!this.onFilterChanges) {
            return;
        }

        const metric = getMetricByDurationType.call(this, MetricsTypes, DurationTypeEnum);

        this.onFilterChanges({
            metric: metric,
            isBoxPlot: metric === null
        });
    }

    function mapFilter(MetricsTypes, DurationTypeEnum) {
        if (this.filter.isBoxPlot) {
            return {
                metric: MetricsTypes.duration,
                durationType: DurationTypeEnum.boxPlot
            };
        }

        if (this.filter.metric === MetricsTypes.medianDuration) {
            return {
                metric: MetricsTypes.duration,
                durationType: DurationTypeEnum.median
            };
        }

        return {
            metric: this.filter.metric,
            durationType: this.filter.metric === MetricsTypes.duration ? DurationTypeEnum.mean : DurationTypeEnum.boxPlot
        };
    }
    
    function getMetricByDurationType(MetricsTypes, DurationTypeEnum) {
        const isDuration = this.currentMetric.id === this.metricDurationId;

        if (isDuration && this.currentDurationType.title === DurationTypeEnum.boxPlot) {
            return null;
        }

        return isDuration && this.currentDurationType.title === DurationTypeEnum.median
            ? MetricsTypes.medianDuration
            : this.currentMetric.id;
    }
})();
